// 时间戳转时间格式
export function formatTime(timestamp) {
  if (!timestamp) return "";
  // 将数字转换为字符串
  const timestampStr = timestamp.toString();
  // 检查字符串的长度
  const length = timestampStr.length;
  // 如果长度是10（可能是秒级的时间戳），则乘以1000转换为毫秒级
  if (length === 10) {
    timestamp * 1000;
  }
  let date = new Date(parseInt(timestamp));
  let Y = date.getFullYear() + "-";
  let M =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1) + "-"
      : date.getMonth() + 1 + "-";
  let D =
    date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
  let h =
    date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
  let m =
    date.getMinutes() < 10
      ? "0" + date.getMinutes() + ":"
      : date.getMinutes() + ":";
  let s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
}
