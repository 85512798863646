<template>
  <div>
    <shop-head :yellowType="true" currentPageName="RFQs"></shop-head>
    <!-- 面包屑 -->
    <breadcrumb pageName="RFQs"></breadcrumb>
    <!-- 主要内容 -->
    <div class="content_wrapper">
      <div class="container">
        <div class="add-btn-list">
          <router-link to="/RFQs/addRFQs">
            <el-button type="warning" size="small" class="add-btn"
              >+Post an RFQ</el-button
            >
          </router-link>
        </div>
        <div class="table-box">
          <el-table
            :header-cell-style="{ background: '#ebecf0', 'font-weight': '500' }"
            :data="tableData"
            style="width: 100%"
          >
            <el-table-column prop="Rfq" label="Rfq" width="800">
              <template v-slot="scope">
                <div class="Rfq-cont" @click="watchRFQs(scope.row)">
                  <p class="sub-title">
                    <span
                      style="margin-right: 10px"
                      v-if="scope.row.createdDt"
                      >{{ scope.row.createdTime }}</span
                    >
                    <span v-if="scope.row.no">id:{{ scope.row.no }}</span>
                  </p>
                  <p>
                    <span>VIN or Car Type：</span>
                    <span v-if="scope.row.vin" style="margin-right: 10px"
                      >vin：{{ scope.row.vin }}</span
                    >
                    <span v-if="scope.row.carType">
                      carType：{{ scope.row.carType }}</span
                    >
                  </p>
                  <p class="product-name">
                    {{ scope.row.productName }}
                  </p>
                  <p class="product-quantity">
                    <span class="quantity">{{ scope.row.quantity }}</span>
                    {{ scope.row.unit }}
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop=""
              label="Quotation from"
              width="180"
            >
              <!-- <template v-slot="scope">
                <div class="quotation-info">
                  <strong style="color: #333">7</strong> Quotes Recieved
                </div>
              </template> -->
            </el-table-column>
            <el-table-column align="right" prop="status" label="Status">
              <template v-slot="scope">
                <el-button
                  @click="watchRFQs(scope.row)"
                  class="watch-btn"
                  type="danger"
                  round
                  >Watch RFQS</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="paginatoin-area">
            <el-pagination
              @current-change="changePage"
              :total="count"
              :page-size="pageSize"
              prev-text="Pre"
              next-text="Next"
              :current-page="page"
              layout="prev, pager, next"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-drawer
      title="RFQ Details"
      :visible.sync="tableVisible"
      direction="rtl"
      size="480px"
      :modal="false"
      class="rfq-drawer"
    >
      <div class="rfq-details">
        <div class="title">{{ tableObj.productName }}</div>
        <div class="details-cont">
          <div class="left-title">Product Name:</div>
          <div class="right-cont">{{ tableObj.productName }}</div>
        </div>
        <div class="details-cont">
          <div class="left-title">VIN or Car Type：</div>
          <div class="right-cont">
            <span v-if="tableObj.vin" style="margin-right: 10px"
              >vin：{{ tableObj.vin }}</span
            >
            <span v-if="tableObj.carType">
              carType：{{ tableObj.carType }}</span
            >
          </div>
        </div>

        <div class="details-cont">
          <div class="left-title">Quantity:</div>
          <div class="right-cont">
            {{ tableObj.quantity }} {{ tableObj.unit }}
          </div>
        </div>
        <div class="details-cont">
          <div class="left-title">Details:</div>
          <div class="right-cont" v-html="tableObj.requireHtml"></div>
        </div>
        <div class="details-cont" v-if="fileList.length > 0">
          <div class="file-box">
            <div
              class="file-item"
              v-for="(item, index) in fileList"
              :key="index"
            >
              <el-image
                v-if="item.imgFileType === 'image'"
                style="border: 0"
                class="upload-show-img"
                :src="item.imgUrl"
                :preview-src-list="imgSrcList"
                :title="item.name"
              >
              </el-image>
              <div
                @click="clickFile(item.imgUrl)"
                v-else
                class="upload-show-img"
                :title="item.name"
              >
                File
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <common-footer></common-footer>
  </div>
</template>

<script>
import shopHead from "../../components/shop/head";
import commonFooter from "../../components/common/footer";
import breadcrumb from "../../components/common/breadcrumb";
import { findByPage } from "@/api/service/RFQsApi";
import { formatTime } from "@/utils/dateFormat";
export default {
  components: {
    shopHead,
    commonFooter,
    breadcrumb,
  },
  data() {
    return {
      page: 1,
      pageSize: 20,
      count: 0,
      tableData: [],
      tableVisible: false,
      tableObj: {},
      imgSrcList: [],
      fileList: [],
    };
  },
  created() {
    this.findByPage();
  },
  methods: {
    findByPage() {
      let param = {
        page: this.page,
        pageSize: this.pageSize,
      };
      findByPage(param).then((res) => {
        let data = res.data;
        this.count = data.count || 0;
        if (data.resultSet && data.resultSet.length > 0) {
          this.tableData = data.resultSet;
          this.tableData.forEach((item) => {
            let createdTime = formatTime(item.createdDt);
            this.$set(item, "createdTime", createdTime);
            if (item.require) {
              this.$set(
                item,
                "requireHtml",
                item.require.replace(/↵/g, "<br>").replace(/\n/g, "<br/>")
              );
            }
          });
        } else {
          this.$message.error(res.data.bizCode.info);
        }
      });
    },
    watchRFQs(item) {
      this.tableVisible = true;
      this.tableObj = item;
      this.fileList = [];
      this.imgSrcList = [];
      let file = [];
      if (item.images) {
        file = item.images.split(",");
        file = file.filter((part) => part.trim() !== "");
        file.forEach((list) => {
          let type = this.isPdfOrImage(list);
          if (type === "image") {
            this.imgSrcList.push(list);
          }
          this.fileList.push({
            imgFileType: type,
            imgUrl: list,
            url: list,
          });
        });
      }
    },
    /** 改变页码 **/
    changePage(val) {
      this.page = val;
      this.findByPage();
    },
    isPdfOrImage(fileName) {
      const extension = fileName.split(".").pop().toLowerCase();
      if (["pdf", "docx", "doc", "xlsx", "xls"].includes(extension)) {
        return "file";
      } else if (["jpg", "jpeg", "png"].includes(extension)) {
        return "image";
      } else {
        return "";
      }
    },
    clickFile(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.content_wrapper {
  width: 100%;
  margin: 0 auto;

  .container {
    width: 1200px;
    margin: 0 auto;
  }

  .add-btn-list {
    margin-top: 19px;
    margin-bottom: 15px;
  }

  .add-btn {
    font-size: 14px;
    background-color: #f8cc04;
    border-color: #f8cc04;
  }

  .table-box {
    margin-bottom: 50px;

    .sub-title {
      color: #666;
      font-size: 12px;
    }

    .product-name {
      font-size: 16px;
      color: #333;
      margin-bottom: 8px;
    }

    .product-quantity {
      font-size: 14px;
      color: #666;

      .quantity {
        color: #333;
      }
    }

    .quotation-info {
      color: #999;
    }

    .watch-btn {
      background: #ff6600;
      border-color: #ff6600;
    }
  }
}

.Rfq-cont {
  cursor: pointer;
}

.paginatoin-area {
  padding-top: 30px;
  text-align: center;

  ::v-deep .el-pagination {
    line-height: 48px;

    button {
      width: auto;
      height: 48px;
      border: 2px solid #fed100;
      box-sizing: border-box;
      padding: 0 25px;

      span {
        font-size: 15px;
      }

      &:hover {
        color: #fed100;
      }
    }

    .btn-next {
      padding-left: 25px;
      border-radius: 0 24px 24px 0;
      border-left: 0;
    }

    .btn-prev {
      padding-right: 25px;
      border-radius: 24px 0 0 24px;
      border-right: 0;
    }

    .el-pager {
      height: 48px;
      padding: 12px 0;
      box-sizing: border-box;
      border-top: 2px solid #fed100;
      border-bottom: 2px solid #fed100;

      li {
        height: 20px;
        line-height: 20px;
        font-size: 15px;
        font-weight: normal;
        border-left: 1px solid #fed100;
        padding: 0 25px;

        &:last-of-type {
          border-right: 1px solid #fed100;
        }

        &.active {
          color: #fed100;
        }

        &:hover {
          color: #fed100;
        }
      }
    }
  }
}

.rfq-drawer {
  font-size: 24px;

  ::v-deep .el-drawer__header {
    padding: 20px 20px;
    border-bottom: 1px solid #e6e7eb;
    margin-bottom: 20px;
  }
}

.rfq-details {
  padding: 0 20px 20px 20px;

  .title {
    font-size: 16px;
    color: #333;
    margin-bottom: 12px;
  }
}

.details-cont {
  width: 100%;
  display: flex;
  font-size: 12px;

  .left-title {
    width: 150px;
    color: #666;
    text-align: left;
  }

  .right-cont {
    width: calc(100% - 150px);
    color: #333;
    font-weight: 700;
    word-wrap: break-word;
  }
}

.file-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 15px;
}

.upload-show-img {
  width: 100px;
  height: 100px;
  line-height: 100px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 20px;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  border: 1px dashed #d9d9d9;
  font-size: 24px;
}
</style>
